.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loader {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}


@-webkit-keyframes ball-pulse-sync {
33% {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}
66% {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
}

@keyframes ball-pulse-sync {
33% {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}
66% {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
}

.ball-pulse-sync > div:nth-child(1) {
-webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}

.ball-pulse-sync {
  position: absolute;
  top: 50%;
  left: 50%;
}

.ball-pulse-sync > div:nth-child(2) {
-webkit-animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(3) {
-webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}

.ball-pulse-sync > div {
background-color: #ee1e79;
width: 15px;
height: 15px;
border-radius: 100%;
margin: 2px;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
display: inline-block;
}
.form-rollz:focus{
  outline: 1px solid #ee1e79;
}
