.franchise-head h1 {
  color: #ee1f79;
  font-size: 45px;
  font-weight: bold;
  line-height: 1.4em;
  text-align: center;
  /* padding: 50px 0px 0px 0px; */
  margin-bottom: 20px;
}
.border-head-bottom {
  width: 13%;
  margin: 0 auto;
  border-bottom: 3px solid #0e72ba;
  margin-bottom: 40px;
}

.Card {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 50px;
  padding: 50px 50px 50px 50px;
  background-color: #ffffff;
}
.side-img img {
  width: 100%;
}
.side-second {
  padding: 0 10px 0 25px;
}
.card-head h1 {
  color: #ee1f79;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.card-bottom-line {
  width: 13%;
  border-bottom: 3px solid #0e72ba;
  margin-bottom: 20px;
}

.card-text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.icon-arrow {
  background-color: #ee1e79;
  color: white;
  border-radius: 50%;
  padding: 3px 4px;
  border-radius: 50%;
}
.card-text h3 {
  color: #0e72ba;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.form-head h3 {
  color: #ee1f79;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.4em;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .card-xs {
    padding: 1rem !important;
  }
  .side-second {
    padding: 0 25px 0 25px !important;
  }
  .card-text p {
    text-align: justify;
    font-size: 16px;
    line-height: 1.4rem;
    font-weight: 400; 
  }
  .card-head h1,
  .form-head h3 {
    font-size: 26px;
    margin-top: 20px;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }

  .form-franchise .btn-submit {
    width: 100% !important;
  }
  .franchise-head h1 {
    font-size: 30px !important;
    margin-bottom: 13px;
  }
  .franchise-head {
    margin-bottom: 15px;
  }
}

.form-rollz  .css-13cymwt-control{
  border: none;
}
.form-rollz  .css-hlgwow{
  padding-left: 0px;
}