.date_section h1 {
  font-size: 55px;
  line-height: 1.2;
  font-weight: 700;
  color: white;
}
.date_section {
  text-decoration: none;
  background-color: #ee1e79;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100px;
}

.date_section div {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 7px;
  color: white;
  text-transform: uppercase;
}
.blog-content {
  padding: 10px 25px 35px;
}
.blog-content a {
  text-decoration: none;
}
.blog-content h1 {
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 50px;
  color: #ee1e79;
}
.blog-content p {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  color: #797979;
}
.btn-read-more {
  padding: 6px 12px;
  border: none;
  background-color: #ee1e79;
  color: white;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 10px;
}
.bd-recent-post h2 {
  font-size: 25px;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  padding-top: 0;
  font-weight: 700;
  letter-spacing: 1px;
  color: #ee1e79;
}
.magnify-glass {
  position: absolute;
  top: 28px;
  left: 10px;
  color: #ee1e79;
}
.bd-recent-post input {
  padding-left: 40px;
}
.bd-recent-post img {
  border-radius: 10px;
}
.bd-recent-post h5 {
  font-size: 15px;
  color: #7b7b7b;
  margin-bottom: 0;
}
.bd-recent-post p {
  color: #ee1e79;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0px;
}
.bd-recent-post .sect-post {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px dotted #ee1e79;
}

.blog-content h3 {
  font-size: 24px;
  color: #ee1e79;
  font-weight: 600;
}
.comment-box {
  padding: 25px;
  background: #fff;
}
.comment-box h3 {
  font-size: 24px;
  color: #ee1e79;
  font-weight: 660;
}
.comment-box .note {
  font-size: 14px;
  color: #a1a2a6;
}
.img-detail img {
  width: 100% !important;
}
.page-item a{
  background: white;
  font-weight: 400 !important;
  margin: 0 5px 5px 0 !important;
  padding: 8px 17px !important;
  color: #7d7c7c;
  text-decoration: none;
  border-radius: 5px;
}

.page-item.active a{
  color: #fff !important;
  background: #ee1e79;
}
.pagination-left{
  margin-left: 98px;
}

@media only screen and (max-width: 600px) {
  .pagination-left {
    margin-left:0px;
  }
  .pagination{
    flex-wrap: wrap;
  }
  .page-item {
    margin-bottom: 21px;
  }

  .date_section {
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    padding: 0 1rem;
  }
  #blog-detail {
    width: 100% !important;
  }
  #column-xs-blog-detail {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  #column-xs {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  #date_sec {
    width: 100% !important;
  }
  #date_sec a {
    flex-direction: row !important;
    padding: 0 1rem;
    gap: 1rem;
    width: 100% !important;
    height: auto !important;
  }
  .blog-content {
    padding: 10px 20px 35px;
  }
  .blog-content p {
    text-align: justify;
  }
  #blog-detail-content .blog-content div h1 {
    line-height: 35px !important;
  }
  .comment-box .btn-submit {
    width: 100% !important;
  }
  .blog-content h1 {
    line-height: 35px !important;
    font-size: 20px !important;
  }
  /* .bd-recent-post .sect-post {
    gap: 20px !important;
  } */
  .content-wrapper .pe-4 {
    padding-right: 0.7rem !important;
  }
  .content-wrapper .ps-4{
    padding-left: 0.7rem !important;
  }
  }

