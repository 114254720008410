@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
.navbar-nav > li > a {
  padding: 15px !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s all;
}
.navbar-nav > li > a:focus {
  color: white;
}
.navbar-nav > li > a:hover {
  color: white;
  scale: 1.1;
}
.bg-nav {
  background-color: #ee1e79;
  height: 100px;
  box-shadow: 0px 0px 32px 0px #ee1e79;
}
.nav-fixed {
  position: sticky;
  min-height: 100px;
  top: 0;
  left: 0;
  z-index: 10;
}

.rs-logob img {
  font-size: 16px;
  width: 150px;
  height: 150px;
  background: white;
  border-radius: 150px;
  box-shadow: 0px 0px 0px 0px #ee1e79;
  -webkit-animation: logos 1s infinite;
  animation: logos 1s infinite;
  left: 44%;
  position: absolute;
  top: 12%;
}
.justify-space-between {
  justify-content: space-between;
}
@keyframes logos {
  from {
    -webkit-box-shadow: 0px 0px 32px 0px #ee1e79;
    -moz-box-shadow: 0px 0px 32px 0px #ee1e79;
    box-shadow: 0px 0px 32px 0px #ee1e79;
  }
  to {
    -webkit-box-shadow: 0px 0px 0px 0px #ee1e79;
    -moz-box-shadow: 0px 0px 0px 0px #ee1e79;
    box-shadow: 0px 0px 0px 0px #ee1e79;
  }
}

.triangle {
  background: url('../images/arrow-red.png')
    left top repeat-x;
  animation: triangles 1s infinite;
}
@keyframes triangles {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 16px 0;
  }
  100% {
    background-position: 32px 0;
  }
}

/* Banner css */

.banner-img {
  background: #0e72ba
    url('../images/cream-bg-02.png');
  background-position-x: 0%;
  background-position-y: 0%;
  margin: 0;
  height: 160px;
  background-position: left bottom !important;
  margin-top: -30px;
  width: 100%;
}
.content-wrapper {
  padding: 80px 0;
  clear: both;
  width: 100%;
  background: #f4f3ef;
}
.navbar-nav > li > a.active {
  animation: zoom-in-zoom-out 2s ease infinite;
  color: white !important;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.15, 1.15);
  }
  100% {
    transform: scale(1, 1);
  }
}

.menu-item:hover .nav-sublink {
  height: auto;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.nav-sublink {
  display: block;
  position: absolute;
  /* left: 0; */
  top: 83%;
  height: 0;
  /* width: 100%; */
  overflow: hidden;
  background: #ee1e79;
  transition: 0.2s;
  padding-left: 0px;
}
.nav-sublink li a {
  display: block;
  position: relative;
  margin: 0;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 13px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.location-modal {
  position: absolute;
  top: 6px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin-left: 6px;
}

.icon-right {
  display: none;
}
/* Responsive NavBar for mobile device */

@media only screen and (max-width: 600px) {

  .withoutcollapse {
    display: block !important;
  }
  #mainl .rs-logob a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #mainl .rs-logob a img {
    left: unset !important;
    top: 50% !important;
    width: 120px;
    height: 120px;
  }
  .bg-nav .container {
    background-color: #ee1e79;
  }
  /* #navbarSupportedContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  } */
  #navbarSupportedContent .order-xs-2 {
    text-align: center;
    order: 2 !important;
    padding-top: 37%;
  }
  .navbar-toggler {
    padding-top: calc(100% - 90%);
  }
  #navbarSupportedContent .order-xs-1 {
    order: 1 !important;
  }
  #navbarSupportedContent .order-xs-3 {
    text-align: center;
    order: 3 !important;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
    border: none;
    outline: 0;
    margin-top: 10px !important;
  }

  .navbar-toggler {
    border: 0;
    color: #fff;
  }
  .navbar-toggler span {
    color: #fff;
  }

  /* Home Page */
  .messes-title {
    /* font-size: 18px !important; */
    font-size: 30px !important;
  }

  .rollz-head h3 {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .nav-sublink {
    width: 100%;
    position: unset !important;
    left: unset !important;
    top: unset !important;
    height: 200px !important;
  }
  .menu-item:hover .nav-sublink {
    height: unset !important;
    width: 100%;
    box-shadow: none;
  }
  .icon-right {
    display: inline-flex;
    font-size: 13px;
    margin-right: 10px;
  }
  .banner-img {
    display: none;
  }
  .content-wrapper {
    margin-top: -21px;
    padding: 75px 0px 50px 0px;
  }
  #location-modal-2 {
    top: 15% !important;
    left: 5% !important;
    text-align: left !important;
  }
  #loca-m-oncoll {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  /* .withoutcollapse {
    display: block !important;
  } */
  /* .withoutcollapse a img {
    width: 100px !important;
    height: 100px !important;
    top: 50%;
    left: unset !important;
    margin: 0 35%;
  } */
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  #navbarSupportedContent .navbar-nav .nav-item a {
    font-size: 13px !important;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: white;
}
.closebtn {
  font-size: 30px !important;
  text-align: right;
  display: flex;
  justify-content: right;
}
