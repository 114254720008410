@media only screen and (max-width: 600px) {
  .pe-xs-0 {
    padding-right: 1rem !important;
  }
  .contact-service-detail {
    height: auto !important;
    padding: 70px 20px !important;
  }
  #get-in-touch {
    margin-top: 2rem;
  }

  #get-in-touch .btn-submit {
    width: 100% !important;
  }
  .form-rollz {
    margin-top: 20px !important;
  }
}
