.bd-footer {
  background-color: #ee1e79;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}
.footer-img {
  width: 100px;
  height: 100px;
  background: white;
  border-radius: 150px;
  box-shadow: 0px 0px 0px 0px #ee1e79;
}
.line-top-white {
  border-bottom: 2px solid #fff;
  margin: 0 15px 18px;
}
.line-top-white::after {
  border-top: 2px solid #fff;
  content: "";
  height: 2px;
  width: 100%;
  display: block;
  margin-bottom: 4px;
}
.rollz-footer h1 {
  color: white;
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
}
.rollz-footer p {
  line-height: 25px;
  font-size: 18px;
  color: white;
  letter-spacing: 1px;
}
.rollz-head h3 {
  font-size: 20px;
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
}
.rollz-head p {
  font-size: 18px;
  color: white;
  letter-spacing: 1px;
}
.rollz-info i {
  color: white;
  font-size: 24px;
}
.rollz-info div,
.rollz-info a {
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
}
.bd-menus ul {
  list-style: none;
}
.bd-menus ul li,
.bd-menus ul li a {
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
}
.btn-view-location {
  padding: 13px 18px;
  color: #ee1e79;
  background-color: white;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 35px;
  font-size: 13px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .footer-img-xs-cener {
    margin-bottom: 3.5rem !important;
    text-align: center;
  }

  .bd-menus h2 {
    margin-top: 1rem !important;
    padding-left: 0 !important;
  }
  .bd-menus ul {
    padding-left: 0 !important;
  }
  .bd-menus ul li {
    width: 50%;
    /* padding: 10px 1rem; */
  }
  .bd-menus ul li a {
    font-size: 15px !important;
  }
  .bd-footer .container .row {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .footer-social{
    margin-bottom: 14%;
  }
}
