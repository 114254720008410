.bd-location-drop {
  border: 1px solid #e5e5e5;
  padding: 6px 15px;
  margin-left: 10px;
  width: 220px;
  font-size: 15px;
}
.location-wise {
  font-size: 20px;
  color: #0e72ba;
  font-weight: 600;
  margin-bottom: 0px;
}
.bg-menu-image {
  background-image: url("../images/blue-bg.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 7px;
}
.bd-menu-box {
  padding: 60px 20px 10px 20px;
}
.menu-box {
  padding: 28px 15px;
  background-color: white;
  text-align: center;
  border-radius: 20px;
  transition: 0.8s all;
}
.menu-box img {
  width: auto;
  height: 150px;
  object-fit: cover;
  /* border-radius: 50%; */
  transition: 0.5s all;
}

.menu-box h5 {
  color: #0e72ba;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}
.menu-box:hover {
  scale: 1.1;
  box-shadow: 0px 0px 10px 0px #ee1e79;
}
.menu-box:hover img {
  border-radius: 30px;
}
.cta-download h5 {
  color: #0e72ba;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}
.download-menu {
  padding: 6px 12px;
  border: none;
  background-color: #0e72ba;
  color: white;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
}

.cta-download {
  padding: 35px 15px;
  background-color: #ff91c1;
  border-radius: 8px;
}
.bd-content-small h3 {
  font-size: 45px;
  color: #ee1e79;
  font-weight: 600;
  text-align: center;
}
.menu-location {
  padding: 17px 15px;
  background-color: #ff91c1;
  border-radius: 8px;
}

/* SubMenu side Bar */

.sidebar-menu {
  width: 22%;
}
.product-list {
  width: 78%;
}
.sidebar-box {
  padding: 13px;
  background-color: white;
  border-radius: 5px;
}
.sidebar-box a {
  font-size: 14px;
  padding: 0px 16px;
  color: black;
  text-decoration: none;
}
.sidebar-box ul {
  margin-bottom: 10px;
  padding-left: 25px;
}
.sidebar-box ul li {
  font-size: 14px;
  list-style: none;
  margin-bottom: 10px;
}
.btn-active-menu a {
  padding: 10px 16px !important;
  background-color: #ee1e79;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
  display: block;
}
.submenu-type h5 {
  font-size: 17px;
}
.cursor-point {
  cursor: pointer;
}
.side-active a {
  animation: fadeRight 1.5s linear infinite;
  color: #ee1e79 !important;
  font-weight: 650 !important;
  border-radius: 5px;
  width: 100%;
  display: block;
  padding: 5px 16px !important;
}

@keyframes fadeRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  75% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@media only screen and (max-width: 600px) {
  #menu-select {
    text-align: center;
  }
  .menu-col-1 {
    margin-bottom: 1rem;
  }
  .menu-location .menu-col-1 .location-wise {
    text-align: center;
  }
  .bd-location-drop {
    margin: 1rem 0;
  }
  .cta-download .row div {
    margin-bottom: 1rem;
  }
  .bd-content-small p {
    text-align: justify;
  }
  .bd-content-small h3 {
    font-size: 24px !important;
  }
}
