@media only screen and (max-width: 600px) {
  .sidebar-menu {
    width: 70%;
    position: absolute;
  }
  .d-xs-block {
    display: block !important;
  }
  .product-list {
    /* margin: 13% !important; */
    width: 100%;
  }
  .bd-menu-box {
    padding-top: 5rem !important;
  }
  .sidebar-box.web{
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .collapse {
    display: block !important;
  }
}
