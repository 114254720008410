body {
  font-family: Montserrat, sans-serif;
}
.slider-section {
  background: url('../images/bg-slick-slider.png')
    repeat center center;
  margin-top: -30px;
}
.slider-text {
  position: relative;
}
.slider-text h1 {
  font-family: moon_flower_boldregular;
  font-size: 60px;
  font-weight: 500;
  line-height: 1.1;
  color: #fff;
  -webkit-animation: 1s cubic-bezier(0.7, 0, 0.3, 1) both animLoadedContent;
  animation: 1s cubic-bezier(0.7, 0, 0.3, 1) both animLoadedContent;
  animation-delay: 0s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  position: relative;
  display: inline-block;
  margin: 0px 10px;
}
.slider-text h1::before,
.slider-text h1::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 10px;
  height: 10px;
  top: 20px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.slider-text h1::before {
  left: -40px;
}
.slider-text h1::after {
  right: -40px;
}
.banner-slick {
  text-align: center;
  padding: 90px 0 0;
}
.slider-text h2 {
  margin-top: 10px;
  color: #fff;
  font-size: 60px;
  text-shadow: -1px 3px 0px #dc0c67;
  letter-spacing: 7px;
  font-weight: bold;
  line-height: 45px;
  text-transform: uppercase;
}
.slick-image {
  margin-top: 40px;
  position: relative;
}
.slick-image img {
  width: 100%;
  height: 100%;
}
.slider-section .container {
  padding: 0 100px;
}
.cdoslick-bottom-bg {
  background-color: #23cfa7;
  border-top: 10px solid #a0ecd9;
  height: 100px;
  margin-top: -80px;
  border-bottom: 10px solid #39e6be;
}
.cdoslick-bottom-border {
  /* background: url(../images/hb-green.png) center center; */
  height: 10px;
}
.style-btn-slider {
  width: 50px;
  height: 50px;
  line-height: 46px;
  font-size: 20px;
  background: #f4f3ef;
  display: block;
  color: #a1a2a6;
  border-radius: 50%;
  position: absolute;
}
.style-btn-slider.slick-left {
  left: -100px;
  top: 80px;
}
.style-btn-slider.slick-left:hover,
.style-btn-slider.slick-right:hover {
  border-radius: 5px;
  transition: 0.2s ease-out;
}
.style-btn-slider.slick-right {
  right: -100px;
  top: 80px;
}
.bg-purple {
  background-color: #a593c2;
}
.bd-icon-cake {
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;
}
.bd-cake-icon {
  font-size: 40px;
  color: white;
}
.letter-space1 {
  margin-top: 15px;
  letter-spacing: 1px;
  font-size: 14px;
  color: white;
  font-weight: 600;
}
.rollz-name {
  position: absolute;
  top: 0;
  font-size: 38px;
  line-height: 30px;
  margin-top: 50px;
  width: 100%;
  text-align: left;
}
.rollz-name p {
  color: white;
  border-bottom: solid 5px #fff;
  padding-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 700;
  font-size: 42px;
  line-height: 39px;
}
.rollz-name span {
  font-size: 22px;
  display: block;
}
.bd-second-roll {
  width: 25%;
}
.bd-second-roll img {
  width: 100%;
  object-fit: cover;
}
.testtimonial-img {
  background: url("../images/dot-purple.png");
  float: left;
  text-align: center;
  width: 100%;
  height: 285px;
  display: flex;
  align-items: center;
}
.testtimonial-img h4 {
  color: #fff;
  font-size: 42px;
  line-height: 40px;
  font-weight: bold;
}
.testtimonial-img p {
  color: white;
}
.bd-slider-testimonial {
  padding: 40px;
  background-color: #f4f3ef;
  height: 285px;
  /* width: 100%; */
}
.testimonial-name {
  font-size: 36px;
  color: #a593c2;
}

.testimonial-text {
  font-size: 25px;
  line-height: 35px;
  color: #a593c2;
}
.text-left {
  text-align: left;
}
.pb-60 {
  padding-bottom: 60px;
}
.pad-section {
  padding-bottom: 60px;
  padding-top: 60px;
}
.roll-location {
  background: #23cfa7;
  border-top: solid 10px #a0ecd9;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 10px solid #39e6be;
}

.messes-round-wrap img {
  margin-top: 30px;
  width: 58px;
  height: 74px;
}
.green-option {
  background: #23cfa7;
}
.yellow-option {
  background: #ffbb63;
}
.blue-option {
  background: #59d4f0;
}
.pink-option {
  background: #fba1a1;
}
.messes-round-wrap {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  text-align: center;
  position: relative;
  top: 40px;
}
.messes-title {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  /* margin-top: 10px; */
}
.green-messes .messes-title {
  color: #23cfa7;
}
.yellow-messes .messes-title {
  color: #ffbb63;
}
.blue-messes .messes-title {
  color: #59d4f0;
}
.pink-messes .messes-title {
  color: #fba1a1;
}
.messes-title-border {
  display: block;
  width: 100px;
  height: 6px;
  margin: 10px auto;
}
.green-messes .messes-title-border {
  background: #23cfa7;
}
.yellow-messes .messes-title-border {
  background: #ffbb63;
}
.yellow-blue .messes-title-border {
  background: #59d4f0;
}
.pink-messes .messes-title-border {
  background: #fba1a1;
}
.h-220 {
  height: 180px;
}
.messes-desc {
  font-size: 16px;
  color: #a1a2a6;
  margin-bottom: 0px;
}
.bg-why {
  background: #9a70bf url() left bottom repeat;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}
.triangle-top {
  height: 15px;
  position: absolute;
  width: 100%;
  background: url(/src/assets/images/arrow.png);
  top: 0;
}
.triangle-bottom {
  height: 15px;
  position: absolute;
  width: 100%;
  background: url(/src/assets/images/arrow.png);
  bottom: -1px;
  transform: rotate(180deg);
}
.bg-light-gray {
  background-color: #f4f3ef;
}
.text-right {
  text-align: right;
}
.rollz-ice-cream-info ol li {
  list-style: none;
}
.rollz-ice-cream-info .count {
  background-color: #61d3fa;
  border-radius: 50%;
  height: 30px;
  margin-right: 10px;
  padding: 0px 10px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  color: #fffc69;
}
.rollz-ice-cream-info.right-side .count {
  margin-right: 0px;
  margin-left: 10px;
}
.rollz-ice-cream-info strong,
.rollz-ice-cream-info p {
  font-size: 15px;
  color: #000;
  letter-spacing: 1px;
}

.banner-slick .container {
  padding: 0 100px;
}
.gallery-banner {
  position: relative;
}
.gallery-banner img {
  width: 100%;
  height: 100%;
}
.purple {
  color: #ee1e79 !important;
}
.slider-text h1::before,
.slider-text h1::after {
  background: #ee1e79 !important;
}
.form-rollz {
  padding: 10px 15px;
  border: none;
  width: 100%;
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 3px;
}
.btn-submit {
  padding: 13px 18px;
  background-color: #ee1e79;
  color: white;
  font-size: 20px;
  letter-spacing: 2px;
  border-radius: 50px;
  width: 50%;
}
.btn-submit:hover {
  background-color: #dc0c67;
  color: white;
}

@font-face {
  font-family: "moon_flower_boldregular";
  src: local("moon_flower_boldregular"),
    url("../Fonts/Moon\ Flower\ Bold\ 400.ttf") format("truetype");
  font-weight: bold;
}
/* Contact CSS */
.contact-service-detail {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  height: 550px;
  overflow: hidden;
  padding: 70px 50px;
}
.overlay-service {
  background-color: #000;
  border-radius: 8px;
  height: 100%;
  opacity: 0.8;
  width: 100%;
}

.contact-service-detail h1 {
  color: #fff;
  font-size: 38px;
  margin-bottom: 30px;
}
.contact-info-service {
  background: #0e72ba;
  border-radius: 50%;
  color: #ced1d5;
  flex: none;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  margin: 5px 12px 0 0;
  text-align: center;
  width: 50px;
}
.contact-service-detail p,
.contact-service-detail a {
  color: #fff;
  font-size: 16px;
}
.contact-service-detail h4 {
  color: #fff;
  font-size: 22px;
}

/* Location Css */
.location-card {
  box-shadow: #c9e8ff 0px 2px 8px 0px;
  /* box-shadow: #c9e8ff 0px 3px 8px; */
}
.location-card-content {
  padding: 30px 15px;
  background-color: white;
  margin-top: -6px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.location-card-content h2 {
  font-size: 24px;
  color: #0e72ba;
  font-weight: bold;
}
.location-card-content i {
  padding-right: 10px;
}
.location-card-content p {
  font-size: 14px;
  color: #000;
  line-height: 2em;
  border-bottom: 1px dotted;
  padding-bottom: 13px;
  letter-spacing: 0.2px;
}
.location-card-content .call {
  font-size: 16px;
  color: #ee1e79;
  font-weight: 550;
  line-height: 1em;
}

.btn-arraow {
  font-size: 13px;
  font-weight: 550;
  background-color: #ee1e79;
  padding: 6px 8px 6px 8px;
  color: white;
  border-radius: 5px;
  height: 100%;
  display: flex;
  align-items: center;
}
.btn-arraow-loc {
  font-size: 11px;
  font-weight: 550;
  background-color: #ee1e79;
  padding: 9px 13px 9px 13px;
  color: white;
  border-radius: 5px;
  height: 100%;
  display: block;
  text-decoration: none;
}
.btn-arraow i {
  padding-right: 0px;
}
.pl-0-location {
  padding-left: 0px;
}

.slick-slide.slick-active.slick-center.slick-current {
  transform: scale(1.14); /* Apply the scale effect to the center slide */
}
.slick-slide {
  transition: transform 0.5s;
}
.messes-show {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 auto;
  background: url("../images/messes.png") center no-repeat;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.messes {
  position: relative;
  height: 220px;
}
.messes .messes-show:hover {
  opacity: 1;
  cursor: pointer;
}
.bd-video-sect {
  background-color: #000;
  width: 340px;
  position: relative;
}
.play-icon {
  position: absolute;
  top: 38%;
  left: 42%;
  cursor: pointer;
}
.play-icon i {
  color: white;
  font-size: 55px;
}

.video-title {
  /* color: #dc0c67; */
  color: rgb(14, 114, 186);
  font-size: 24px;
  /* text-shadow: -1px 3px 0px white; */
  letter-spacing: 1px;
  font-weight: bold;
}
.video-modal .modal-header {
  border-bottom: 0;
  background: #e65f9e;
}
.badge-primary {
  background: #0e72ba;
  padding: 15px;
  font-size: 16px;
  margin: 10px;
}
.review-text {
  font-weight: 600;
  font-size: 20px;
}
.review-text span {
  color: #0e72ba;
}
.review-text p {
  font-size: 13px;
  text-align: left;
}
.whatsapp-menu h5 {
  padding: 0px 0px 15px 0px;
}
.cta-whatsapp h5 {
  color: #0e72ba;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.cta-whatsapp {
  padding: 25px 15px;
  background-color: #ff91c1;
  border-radius: 8px;
}
.download-whatsapp {
  padding: 12px 22px;
  border: none;
  background-color: #0e72ba;
  color: white;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
}
.pading-10 {
  padding: 10px 0px;
}
.franchise-head h3 {
  color: #0e72ba;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.video-modal .modal-header {
  border-bottom: 0;
}

.location-modal .modal-content {
  background-color: #ffbedb;
  border: none;
}
.btn-location-ask {
  padding: 10px 18px;
  color: white;
  background-color: #dc0c67;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  display: block;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.video-pop .modal-content {
  background-color: #ffbedb;
  border: none;
}
.social-side-link{
  position: fixed;
  top: 50%;
  text-align: center;
  font-size: 17px;
  transition: .5s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none;
  width: 60px;

}
.social-side-link .insta{
  color: #fff;
  padding: 10px 2px;
  border-top-right-radius: 10px;
  background: linear-gradient(135deg,#582bcd 0,#ed4c33 50%,#f7d36f 100%);
}
.social-side-link .tiktok{
  border-bottom-right-radius: 10px;
  padding: 10px 2px;
  background-color: black;
  color: #fff;
}
.prefer-input{
  padding: 3px 15px;
}

@media only screen and (max-width: 600px) {
  .withoutcollapse a img {
    width: 100px !important;
    height: 100px !important;
    top: 50%;
    left: auto !important;
    margin: 0 0;
  }
  .slider-text h2 {
    font-size: 35px !important;
  }
  .style-btn-slider.slick-right {
    right: 6%;
    top: 0px;
    z-index: 1;
  }
  .style-btn-slider.slick-left {
    left: 6%;
    top: 0px;
    z-index: 1;
  }
  .flext-xs-column {
    flex-direction: column;
  }
  .bd-second-roll {
    width: unset !important;
    padding-top: 0px;
  }
  .img-xs-fluid {
    width: 100%;
  }
  .bd-second-roll .w-xs-100 {
    width: 100% !important;
  }
  .bd-second-roll .w-xs-100 img {
    width: 100% !important;
  }
  #w-xs-100-0 {
    width: 100% !important;
  }
  #w-xs-100-1 {
    width: 100% !important;
  }
  #w-xs-100-2 {
    width: 100% !important;
  }
  .w-xs-100 img {
    width: 100% !important;
    /* padding: 1rem 0; */
  }
  .bd-slider-testimonial {
    height: unset;
  }
  .bd-video-sect {
    width: 100%;
    height: 400px;
    margin: 1rem 0;
  }
  .col-xs-12 {
    width: 100% !important;
  }
  #slick-right {
    right: 0px !important;
  }
  #slick-left {
    left: 0px !important;
  }

  #my-cont {
    padding: 0 0.5rem !important;
  }

  .slider-text h1 {
    font-size: 40px !important;
  }
  .slick-slide img {
    display: unset !important;
    width: 75% !important;
  }
  #slick-slide-2-img {
    width: 100% !important;
  }
  .pad-section .btn-submit {
    width: 100% !important;
  }
  .cta-whatsapp h5 {
    font-size: 20px;
  }
  .form-name {
    margin-bottom: 15px;
  }
  .badge-primary {
    padding: 10px;
    font-size: 12px;
    margin: 6px;
  }
  #p-xs-0 {
    padding: 0 1rem !important;
  }

  .text-xs-left {
    text-align: left !important;
  }
  #jc-xs-fs {
    justify-content: flex-start !important;
  }
  .rollz-ice-cream-info.right-side .count {
    margin-right: 10px !important;
    margin-left: 0 !important;
    padding: 0px 10px;
  }
  #my-cont .row {
    padding-top: 0 !important;
  }
  .cdoslick-bottom-bg {
    margin-top: -45px !important;
  }
  .slick-initialized {
    padding-right: 0 !important;
  }
  .px-xs-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .testtimonial-img-sec {
    display: flex !important;
  }
  .testimonial-text {
    font-size: 15px !important;
    line-height: 20px;
  }
  #testtimonial-img-1 {
    display: none;
  }
  .testimonial-name {
    font-size: 22px !important;
  }
  .pad-section {
    padding-bottom: 5rem;
    padding-top: 1rem;
  }
  .rollz-ice-cream-info p {
    font-size: 14px !important;
  }
  /* .rollz-ice-cream-info p strong {
    margin-bottom: 0 !important;
  } */
  .rollz-ice-cream-info .p-xs-0 div p:first-child {
    margin-bottom: 0 !important;
  }
  .contain-padi {
    padding: 0rem 0.6rem;
  }

  .bd-slider-testimonial {
    padding: 20px;
  }
  .bd-menus ul li,
  .bd-menus ul li a {
    font-size: 16px !important;
  }
}

